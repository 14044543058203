<template>
  <div>
    <vs-row class="items-start">
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
        <vx-card class="custom-vx-card">
          <vs-button flat v-round class="flex primary ml-auto mb-8" @click="addBankAccountPopup">
          <div class="flex items-center">
            <plus-icon size="1.5x" class="button-icon"></plus-icon>Add Accounts</div>
          </vs-button>

          <!-- bank list starts here -->
          <div class="flipbanks">
            <div class="debit-card flex flex-col" v-for="(bank, index) in bankAccountsList" :key="index">
              <div class="card__header flex justify-between card-header mb-0">
                <div class="flex flex-col">
                  <h4 class="m-0 text-xl font-medium mb-1">{{ bank.accountName }}</h4>
                  <p class="font-light text-base">Account ID: {{ bank.bankAccountId }}</p>
                </div>
                <edit-2-icon class="edit-card" style="width: 20px" @click="handleEditPopup(bank, index)"></edit-2-icon>
              </div>
              <div class="card__body">
                <div class="card__content">
                  <vs-row>
                    <span class="font-light text-base">Account Name</span>
                  </vs-row>
                  <vs-row>
                    <h6 class="font-light text-base">{{ bank.displayName }}</h6>
                  </vs-row>
                </div>
                <div class="flex w-full my-5">
                  <div class="card__content" style="margin-right:100px;">
                    <vs-row>
                      <span class="font-light text-base">BSB</span>
                    </vs-row>
                    <vs-row>
                      <h6 class="font-light text-base">{{ bank.bsb }}</h6>
                    </vs-row>
                  </div>
                  <div class="card__content">
                    <vs-row><span class="font-light text-base">Account Number</span></vs-row>
                    <vs-row><h6 class="font-light text-base">{{ bank.accountNumber }}</h6></vs-row>
                  </div>
                </div>
                <vs-button v-round class="w-full mb-4" @click="addTill(bank, index)" v-if="bank.tillConnectorDetails.length == 0">Configure Till Connector</vs-button>
                <vs-button v-round class="w-full only-border-btn mb-4" @click="editTill(bank, index)" v-if="bank.tillConnectorDetails.length">Edit Till Connector</vs-button>
                <vs-button v-round class="w-full mb-4" @click="handleAccountType(bank, 'disbursements', 'add')" v-if="!bank.disbursemAccount">Configure disbursement mAccount</vs-button>
                <vs-button v-round class="w-full only-border-btn mb-4" @click="handleAccountType(bank, 'disbursements', 'update')" v-if="bank.disbursemAccount">Edit disbursement mAccount</vs-button>
                <vs-button v-round class="w-full mb-4" @click="handleAccountType(bank, 'collections', 'add')" v-if="!bank.collectionmAccount">Configure collections mAccount</vs-button>
                <vs-button v-round class="w-full only-border-btn mb-4" @click="handleAccountType(bank, 'collections', 'update')" v-if="bank.collectionmAccount">Edit collections mAccount</vs-button>
              </div>
            </div>
          </div>

          <!-- bank list ends here -->

          <!-- bank account form popup starts here  -->
          <vs-popup class="add-bank-popup" :active.sync="addBankAccountPopupActive" @close="closeBankAccountForm" :title="!isUpdate ? 'Add Platform account' : 'Edit Collections bank account'">
            <vs-row>
              <div class="flex mb-8">
                <div class="input-grp">
                  <label>Account Name <span class="required-text">*</span></label>
                  <vs-input
                    class="w-full"
                    v-validate="'required'"
                    data-vv-validate-on="blur"
                    data-vv-as="account name"
                    data-vv-scope="addBank"
                    v-model="bankAccount.accountName"
                    name="accountName"
                  />
                  <span class="text-danger text-sm" v-show="errors.has('addBank.accountName')">{{ errors.first("addBank.accountName") }}</span>
                </div>
              </div>

              <div class="flex mb-8">
                <div class="input-grp">
                  <label class="w-full text-xm font-normal block">BSB <span class="required-text">*</span></label>
                  <the-mask
                    class="vs-input"
                    :mask="['###-###']"
                    v-model="bankAccount.bsb"
                    name="bsb"
                    id="bsb"
                    @change.native="handleBsb"
                    @blur.native="handleBsb"
                  />

                  <span class="text-danger text-sm block py-3" v-show="errors.has('bsb')">{{ errors.first("bsb") }}</span>
                </div>
                <div class="input-grp">
                  <label class="w-full text-xm font-normal block">Account Number <span class="required-text">*</span></label>
                  <vs-input
                    v-validate="'required'"
                    data-vv-scope="addBank"
                    v-model="bankAccount.accountNumber"
                    type="number"
                    data-vv-validate-on="blur"
                    data-vv-as="account number"
                    name="accountNumber"
                  ></vs-input>

                  <span class="text-danger text-sm block py-3" v-show="errors.has('addBank.accountNumber')">{{ errors.first("addBank.accountNumber") }}</span>
                </div>
              </div>

              <div class="mb-8">
                <div class="input-grp">
                  <label class="w-full text-xm font-normal">Display Name <span class="required-text">*</span></label>
                  <vs-input
                    v-validate="'required|max:40'"
                    v-model="bankAccount.displayName"
                    data-vv-scope="addBank"
                    data-vv-validate-on="blur"
                    data-vv-as="display name"
                    name="displayName"
                  />
                  <span class="text-danger text-sm block py-3" v-show="errors.has('addBank.displayName')">{{ errors.first("addBank.displayName")}}</span>
                </div>
              </div>

              <vs-col class="flex justify-end items-center">
                <vs-button
                  class="text-sm"
                  size="large"
                  v-if="!isUpdate"
                  v-round
                  :disabled="validateBank"
                  color="primary"
                  @click="createFlipBankAccount"
                  >Add Account</vs-button
                >
                <vs-button
                  v-if="isUpdate"
                  class="text-sm"
                  v-round
                  size="large"
                  :disabled="validateBank"
                  color="primary"
                  @click="updateBankAccount"
                  >Update Account</vs-button
                >
                <a class="text-sm flex items-center underline mid-blue ml-8" @click="closeBankAccountForm">Cancel</a>
              </vs-col>
            </vs-row>
          </vs-popup>
          <!-- bank account form popup ends here  -->

          <!-- till connector form popup starts here -->

          <vs-popup class="configure-till-connector-popup" :active.sync="configureTillConnectorPopupActive" @close="closeTillConnectorModal" :title="`Configure Till Connector - ${bankDisplayName}`">
            <p class="block text-base mb-8 text-grey">
              Configure Till connector for this account
            </p>

            <div class="md:flex mb-8">
              <div class="input-grp">
                <label for="connectorName" class="w-full text-xm font-normal">Name <span class="required-text">*</span></label>
                <vs-input
                  v-model="tillConnector.tillName"
                  data-vv-validate-on="blur"
                  data-vv-as="connector name"
                  v-validate="'required|max:100'"
                  data-vv-scope="addTill"
                  name="connectorName"
                  id="connectorName"
                />
                <span class="text-danger text-sm py-3 block" v-show="errors.has('addTill.connectorName')">{{ errors.first("addTill.connectorName") }}</span>
              </div>
              <div class="input-grp">
                <label for="integrationKey" class="w-full text-xm font-normal">Public integration key<span class="required-text">*</span></label>
                <vs-input
                  v-model="tillConnector.publicIntegration"
                  data-vv-validate-on="blur"
                  data-vv-as="itegration key"
                  v-validate="'required|max:100'"
                  name="integrationKey"
                  id="integrationKey"
                  data-vv-scope="addTill"
                />
                <span class="text-danger text-sm py-3 block" v-show="errors.has('addTill.integrationKey')">{{ errors.first("addTill.integrationKey") }}</span>
              </div>
            </div>
            <div class="md:flex mb-8">
              <div class="input-grp">
                <label for="apiKey" class="w-full text-xm font-normal"
                  >API key <span class="required-text">*</span></label
                >
                <vs-input
                  v-model="tillConnector.apiKey"
                  data-vv-validate-on="blur"
                  data-vv-as="apikey"
                  data-vv-scope="addTill"
                  v-validate="'required|max:100'"
                  name="apiKey"
                  id="apiKey"

                />
                <span class="text-danger text-sm py-3 block" v-show="errors.has('addTill.apiKey')">{{ errors.first("addTill.apiKey") }}</span>
              </div>
              <div class="input-grp">
                <label for="sharedSecret" class="w-full text-xm font-normal">Shared secret <span class="required-text">*</span></label>
                <vs-input
                  v-model="tillConnector.sharedSecret"
                  data-vv-validate-on="blur"
                  data-vv-scope="addTill"
                  data-vv-as="shared secret"
                  v-validate="'required|max:100'"
                  name="sharedSecret"
                  id="sharedSecret"
                />
                <span class="text-danger text-sm py-3 block" v-show="errors.has('addTill.sharedSecret')">{{ errors.first("addTill.sharedSecret") }}</span>
              </div>
            </div>
            <div class="md:flex mb-8">
              <div class="input-grp">
                <label for="flipUsername" class="w-full text-xm font-normal">API user name <span class="required-text">*</span></label>
                <vs-input
                  v-model="tillConnector.flipUsername"
                  data-vv-validate-on="blur"
                  data-vv-scope="addTill"
                  data-vv-as="flip Username"
                  v-validate="'required|max:100'"
                  name="flipUsername"
                  id="flipUsername"
                />
                <span class="text-danger text-sm py-3 block" v-show="errors.has('addTill.flipUsername')">{{ errors.first("addTill.flipUsername") }}</span>
              </div>
              <div class="input-grp">
                <label for="flipPassword" class="w-full text-xm font-normal">API password <span class="required-text">*</span></label>
                <vs-input
                  v-model="tillConnector.flipPassword"
                  data-vv-validate-on="blur"
                  data-vv-scope="addTill"
                  data-vv-as="flip Password"
                  v-validate="'required|max:100'"
                  name="flipPassword"
                  id="flipPassword"
                />
                <span class="text-danger text-sm py-3 block" v-show="errors.has('addTill.flipPassword')">{{ errors.first("addTill.flipPassword") }}</span>
              </div>
            </div>
            <vs-col class="flex justify-end ">
              <vs-button class="text-sm mx-4" v-round size="large" color="primary" @click="configureTillConnector" v-if="!this.isUpdateTill">Save</vs-button>
              <vs-button class="text-sm mx-4" v-round size="large" color="primary" @click="updateTillConnector" v-if="this.isUpdateTill">Update</vs-button>
              <a class="text-sm flex items-center underline mid-blue" @click="closeTillConnectorModal">Cancel</a>
            </vs-col>
          </vs-popup>

          <!-- mAccount form popup starts here -->
          <vs-popup class="configure-till-connector-popup" :active.sync="mAccountPopupActive" @close="closemAccountModal" :title="`Edit mAccount for ${accountType}`">
            <p class="block text-base mb-8 text-grey">16 digit mAccount ID, used for {{usage}} payments for any product using this platform account</p>
            <div class="grid grid-cols-2 gap-x-5 gap-y-8 my-5">
              <div>
                <label for="mAccount" class="w-full text-base font-normal">mAccount ID <span class="required-text">*</span></label>
                <vs-input v-model="mAccountId" data-vv-validate-on="blur" data-vv-as="mAccount Id" v-validate="'required|length:16'" name="mAccountId" id="mAccountId" />
                <span class="text-danger text-sm py-3 block" v-show="errors.has('mAccountId')">{{ errors.first("mAccountId") }}</span>
              </div>
              <div>
                <label for="mAccountApiKey" class="w-full text-base font-normal">mAccount API Key <span class="required-text">*</span></label>
                <vs-input v-model="mAccountApiKey" data-vv-validate-on="blur" data-vv-as="mAccount Api Key" v-validate="'required'" name="mAccountApiKey" id="mAccountApiKey" />
                <span class="text-danger text-sm py-3 block" v-show="errors.has('mAccountApiKey')">{{ errors.first("mAccountApiKey") }}</span>
              </div>
            </div>
            <vs-col class="flex justify-end">
              <vs-button class="text-sm" v-round size="large" color="primary" @click="savemAccountId" :disabled="validateAccount">Save</vs-button>
              <a class="text-sm flex items-center underline mid-blue ml-8" @click="closemAccountModal">Cancel</a>
            </vs-col>
          </vs-popup>
          <!-- mAccount form popup ends here -->
        </vx-card>
      </vs-col>
    </vs-row>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import vPagination from "@/views/components/pagination";
import { TheMask } from "vue-the-mask";
import { Edit2Icon, PlusIcon } from "vue-feather-icons";

export default {
  components: { vPagination, TheMask, PlusIcon, Edit2Icon },

  data() {
    return {
      clientUrl: process.env.VUE_APP_CLIENT_API,
      isUpdate: false,
      isUpdateTill: false,
      bankAccountsList: [],
      addBankAccountPopupActive: false,
      bankObjectId: "",
      bankObjectIdForTill: "",
      bankObjectIdFormAccount: "",
      bankDisplayName: "",
      bankAccount: {
        accountName: "",
        bsb: "",
        accountNumber: "",
        displayName: "",
      },
      configureTillConnectorPopupActive: false,
      tillObjectId: "",
      tillConnector: {
        tillName: "",
        publicIntegration: "",
        apiKey: "",
        sharedSecret: "",
        flipUsername: "",
        flipPassword: "",
      },
      mAccountPopupActive: false,
      mAccountId: "",
      mAccountApiKey: "",
      accountType: "",
      usage: "",
    };
  },
  methods: {
    ...mapActions("flipBankAccounts", ["fetchAllFlipBankAccounts", "saveFlipBankAccount", "updateFlipBankAccount"]),
    ...mapActions("connector", ["storeAdminConnector", "updateAdminConnectorById"]),

    addTill(bank, index) {
      this.bankObjectIdForTill = bank._id;
      this.configureTillConnectorPopupActive = true;
      this.bankDisplayName = bank.displayName;
    },

    editTill(bank, index) {
      this.isUpdateTill = true;
      this.bankDisplayName = bank.displayName;
      this.tillObjectId = bank.tillConnectorDetails[0]._id;
      this.configureTillConnectorPopupActive = true;
      this.tillConnector.tillName = bank.tillConnectorDetails[0].tillName;
      this.tillConnector.publicIntegration = bank.tillConnectorDetails[0].publicIntegration;
      this.tillConnector.apiKey = bank.tillConnectorDetails[0].apiKey;
      this.tillConnector.sharedSecret = bank.tillConnectorDetails[0].sharedSecret;
      this.tillConnector.flipUsername = bank.tillConnectorDetails[0].flipUsername;
      this.tillConnector.flipPassword = bank.tillConnectorDetails[0].flipPassword;
    },

    closeTillConnectorModal() {
      this.configureTillConnectorPopupActive = false;
      this.tillConnector = {};
      this.bankObjectIdForTill = "";
      this.isUpdateTill = false;
    },

    async configureTillConnector() {
      this.tillConnector.bankAccountId = this.bankObjectIdForTill;
      await this.storeAdminConnector(this.tillConnector).then((res) => {
        this.tillConnector = {};
        this.showMessage("Success", "Till connector for the selected Flip Bank Account has been created successfully.", "success", "icon-check-circle");
        this.configureTillConnectorPopupActive = false;
        this.getAllBankAccounts();
      }).catch((ex) => {
        this.showMessage("Error", ex.data.message, "danger", "icon-times");
      });
    },

    async updateTillConnector() {
      await this.updateAdminConnectorById({ id: this.tillObjectId, data: this.tillConnector }).then((res) => {
        this.tillConnector = {};
        this.showMessage("Success", "Till connector for the selected Flip Bank Account has been created successfully.", "success", "icon-check-circle");
        this.configureTillConnectorPopupActive = false;
        this.getAllBankAccounts();
      }).catch((ex) => {
        this.showMessage("Error", ex.data.message, "danger", "icon-times");
      });
    },

    addBankAccountPopup() {
      this.addBankAccountPopupActive = true;
    },

    closeBankAccountForm() {
      this.addBankAccountPopupActive = false;
      this.isUpdate = false;
      this.errors.clear();
    },

    handleEditPopup(bankData, index) {
      this.isUpdate = true;
      this.addBankAccountPopupActive = true;
      this.accountId = bankData.bankAccountId;
      this.bankObjectId = bankData._id;
      this.bankAccount.accountName = bankData.accountName;
      this.bankAccount.accountNumber = bankData.accountNumber;
      this.bankAccount.bsb = bankData.bsb;
      this.bankAccount.displayName = bankData.displayName;
      this.tillConnectorDetailsOfBank = bankData.tillConnectorDetails;
      this.handleBsb();
    },

    showMessage(title, message, color, icon) {
      this.$vs.notify({
        title: title,
        text: message,
        color: color,
        iconPack: "feather",
        position: "top-right",
        icon: icon,
      });
    },

    async getAllBankAccounts() {
      await this.fetchAllFlipBankAccounts().then((res) => {
        this.bankAccountsList = res.data.data.docs;
      });
    },

    async createFlipBankAccount() {
      await this.saveFlipBankAccount(this.bankAccount).then((res) => {
        this.bankAccount = {};
        this.showMessage("Success", "Flip Bank Account has been created successfully.", "success", "icon-check-circle");
        this.addBankAccountPopupActive = false;
        this.getAllBankAccounts();
      }).catch((ex) => {
        this.showMessage("Error", ex.data.message, "danger", "icon-times");
      });
    },

    async updateBankAccount() {
      await this.updateFlipBankAccount({ id: this.bankObjectId, info: this.bankAccount }).then((res) => {
        this.showMessage("Success", "Flip Bank Account has been updated successfully.", "success", "icon-check-circle");
        this.addBankAccountPopupActive = false;
        this.getAllBankAccounts();
      }).catch((ex) => {
        this.showMessage("Error", ex.data.message, "danger", "icon-times");
      });
    },

    handleAccountType(bank, type, mode) {
      this.mAccountPopupActive = true;
      this.accountType = type;
      this.bankObjectIdFormAccount = bank._id;
      if (type == "disbursements") {
        this.mAccountId = bank.disbursemAccount ? bank.disbursemAccount.accountNumber : "";
        this.mAccountApiKey = bank.disbursemAccount ? bank.disbursemAccount.apiKey : "";
        this.usage = "disbursing";
      } else {
        this.mAccountId = bank.collectionmAccount ? bank.collectionmAccount.accountNumber : "";
        this.mAccountApiKey = bank.collectionmAccount ? bank.collectionmAccount.apiKey : "";
        this.usage = "collecting";
      }

      this.handlemAccount(mode);
    },

    closemAccountModal() {
      this.mAccountPopupActive = false;
      this.accountType = "";
      this.bankObjectIdFormAccount = "";
      this.mAccountId = "";
      this.mAccountApiKey = "";

      if (this.errors.has("mAccountId")) {
        this.errors.remove("mAccountId");
      }

      if (this.errors.has("mAccountApiKey")) {
        this.errors.remove("mAccountApiKey");
      }
    },

    async savemAccountId() {
      if (!this.mAccountId || !this.mAccountApiKey) {
        this.handlemAccount();
        return;
      }

      const updateAccount = (this.accountType == "disbursements") ? "disbursemAccount" : "collectionmAccount";

      let payload = {
        id: this.bankObjectIdFormAccount,
        info: {
          [updateAccount]: {
            accountNumber: this.mAccountId,
            apiKey: this.mAccountApiKey
          }
        }
      };

      this.$vs.loading();

      await this.updateFlipBankAccount(payload)
        .then((res) => {
          this.showMessage("Success", "Account Id has been updated successfully.", "success", "icon-check-circle");
          this.mAccountPopupActive = false;
          this.getAllBankAccounts();
          this.$vs.loading.close();
        }).catch((ex) => {
          this.showMessage("Error", ex.data.message, "danger", "icon-times");
          this.$vs.loading.close();
        });
    },

    handleBsb() {
      let bsbValid = true;
      let bsbRequired = false;

      if (!this.bankAccount.bsb || this.bankAccount.bsb.length != 6) {
        bsbValid = false;
        bsbRequired = (this.bankAccount.bsb.length == 0);
      }

      if (bsbValid) {
        this.errors.remove("bsb");
      } else if (!this.errors.has("bsb")) {
        const errorMessage = bsbRequired ? "The bsb field is required" : "The bsb field is invalid";
        this.errors.add({
          "field": "bsb",
          "msg": errorMessage
        });
      }
    },

    handlemAccount(mode = "update") {
      if (mode == "add") {
        return;
      }

      let accountValid = true;
      let accountRequired = false;
      let accountKeyRequired = (this.mAccountApiKey.length == 0);

      if (!this.mAccountId || this.mAccountId.length != 16) {
        accountValid = false;
        accountRequired = (this.mAccountId.length == 0);
      }

      if (accountValid) {
        this.errors.remove("mAccountId");
      } else if (!this.errors.has("mAccountId")) {
        const errorMessage = accountRequired ? "The mAccount Id field is required" : "The mAccount Id length must be 16";
        this.errors.add({
          "field": "mAccountId",
          "msg": errorMessage
        });
      }

      if (accountKeyRequired) {
        this.errors.add({
          "field": "mAccountApiKey",
          "msg": "The mAccount Api Key field is required"
        });
      } else {
        this.errors.remove("mAccountApiKey");
      }
    }
  },

  created() {
    this.getAllBankAccounts();
  },

  computed: {
    currentBankName() {
      const item = this.bankAccountsList.find((el) => el.bankAccountId === this.accountId);
      return item ? "Configure Till connector - " + item.accountName : "Configure Till connector";
    },

    validateBank() {
      return !this.bankAccount.accountName || !this.bankAccount.bsb || !this.bankAccount.accountNumber || !this.bankAccount.displayName || this.errors.has("bsb");
    },

    validateAccount() {
      return (this.errors.has("mAccountId") || this.errors.has("mAccountApiKey"));
    }
  },
};
</script>

